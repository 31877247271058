import React from "react";

import LoadingScreen from "../../components/LoadingScreen";
import MerchantCloseModal from "./MerchantCloseModal";
import ModalPriceError from "../../components/ModalPriceError";
import MerchantNoMenu from "./NoMenuModal";
import ModalExpireMerchant from "../../components/ModalExpireMerchant";

const MerchantModals = ({
  isFetch,
  bMerchantMenu,
  isMerchantOpenState,
  openCloseModal,
  setOpenCloseModal,
  openPriceError,
  setOpenPriceError,
  openExpireModal,
  isMoreInfoOpen,
  setIsMoreInfoOpen,
  merchantData,
  bMerchantData,
  openReviewModal,
  setOpenReviewModal,
  isMobile,
  rating,
  merchantLocation,
  t,
}) => (
  <>
    {!isFetch && <LoadingScreen />}
    {isFetch && bMerchantMenu.length && !isMerchantOpenState ? (
      <MerchantCloseModal
        open={openCloseModal}
        setOpenClose={setOpenCloseModal}
      />
    ) : null}
    {openPriceError && (
      <ModalPriceError
        open={openPriceError}
        handleClose={() => setOpenPriceError(false)}
      />
    )}
    {isFetch && !bMerchantMenu.length ? <MerchantNoMenu open={true} /> : null}
    {isFetch && (
      <ModalExpireMerchant
        open={openExpireModal}
        phone={bMerchantData.contract_number}
      />
    )}
    {/* {isFetch && !(bMerchantService.delivery_cash || isVerified) && (
        <ModalNoPayment
          open={openNoPayment}
          setOpenNoPayment={setOpenNoPayment}
          phone={bMerchantData.contract_number}
        />
      )} */}
    {/* {isFetch && !bMerchantService.pickup && !bMerchantService.delivery && (
        <ModalNoService
          open={openNoService}
          setOpenNoService={setOpenNoService}
          phone={bMerchantData.contract_number}
        />
      )} */}
  </>
);

export default MerchantModals;
