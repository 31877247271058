import React from "react";
import packageJson from "../../../package.json";

function VersionPage() {
  return (
    <div className="version-info">
      <h2>Application Version</h2>
      <p>Version: {packageJson.version}</p>
    </div>
  );
}

export default VersionPage;
