import { useGoogleOneTapLogin } from "@react-oauth/google";
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import { ssoThirdParty } from "../libs/authAPI/loginAPI";
import { getCustomerByUUID } from "../libs/customerAPI/getCustomer";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../stores/useAuthStore";
import useModalLoading from "../stores/useModalLoading";
import { auth } from "../utils/firebaseAuth";
import { useEffect } from "react";

const GoogleOneTap = () => {
  const { login: loginStore, isAuthenticated } = useAuthStore();

  useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      // console.log("Credential Response:", credentialResponse); // Log the credential response
      if (!credentialResponse.credential) {
        console.error("No credential received");
        return;
      }

      // Decode the JWT for inspection (optional, for debugging purposes)
      const decodedCredential = JSON.parse(
        atob(credentialResponse.credential.split(".")[1])
      );
      console.log("Decoded Credential:", decodedCredential);

      const credential = GoogleAuthProvider.credential(
        credentialResponse.credential
      );
      try {
        const response = await signInWithCredential(auth, credential);
        console.log("Firebase sign-in response:", response);
        const tokenResponse = response._tokenResponse;
        const body = {
          email: tokenResponse.email,
          firstName: tokenResponse.firstName,
          lastName: tokenResponse.lastName,
          uuid: tokenResponse.localId,
          idToken: tokenResponse.idToken,
          loginType: tokenResponse.providerId,
        };
        const res = await ssoThirdParty(body);
        const token = res.data.token;
        document.cookie = `token=${token}; path=/`;
        try {
          const customerRes = await getCustomerByUUID(res.data.customersUUID);
          const user = {
            email: customerRes.data.email,
            first_name: customerRes.data.first_name,
            last_name: customerRes.data.last_name,
            phone: customerRes.data.phone ?? "",
            uuid: customerRes.data.uuid,
            favorite: customerRes.data.favorite_bmerchants ?? [],
          };
          loginStore(user);
          window.location.reload();
        } catch (customerError) {
          console.error("Error fetching customer data:", customerError);
          const data = res.data;
          const user = {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            phone: data.phone ?? "",
            uuid: data.customersUUID,
            favorite: [],
          };
          loginStore(user);
          window.location.reload();
        }
      } catch (err) {
        console.error("Error during sign-in with credential:", err);
      }
    },
    onError: (error) => {
      console.error("Error during Google One Tap login:", error);
    },
    disabled: isAuthenticated,
  });

  return (
    <div>
      {/* Optionally, you can have a fallback button for manual login */}
      {/* <button onClick={() => window.google.accounts.id.prompt()}>
        Log in with Google
      </button> */}
    </div>
  );
};

export default GoogleOneTap;
