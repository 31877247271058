// CartPage.js
import React, { useState, useEffect } from "react";
import CartItem from "../../components/cart/CartItem"; // Make sure this path is correct
import SlidingButtonGroup from "../../components/cart/SlidingButtonGroup"; // Ensure this is the correct path
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  TextField,
  Divider,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationStore } from "../../stores/useLocationStore";
import { useAuthStore } from "../../stores/useAuthStore";
import { useGuestLogin } from "../../stores/useGuestLogin";
import {
  getBMerchantById,
  getMerchantById,
  getServiceBMerchant,
} from "../../libs/merchantAPI/merchantAPI";
import TextInput from "../../components/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { qrCartDetailScheme } from "../../libs/authScheme";
import LoadingScreen from "../../components/LoadingScreen";
import ModalPriceError from "../../components/ModalPriceError";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import PaymentOptions from "../CartPage/PaymentOptions";
import useAvailablePaymentList from "../../stores/useAvailablePaymentList";
import { paymentData } from "../CartPage/paymentData";
import useFetchMerchantData from "../../stores/useFetchMerchantData";
import { width } from "@mui/system";
import CouponModal from "../CartPage/CouponModal";

const QrCartPage = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [orderdataResponse, setOrderdataResponse] = useState([]);
  const { user } = useAuthStore();
  const { guest_user } = useGuestLogin();
  const navigator = useNavigate();
  const { merchant_id, bmerchant_id } = useParams();
  const [openPriceError, setOpenPriceError] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const { bmerchantData, merchantData, bmerchantService, isFetch } =
    useFetchMerchantData(bmerchant_id, merchant_id);
  const availablePaymentList = useAvailablePaymentList(
    bmerchantService,
    "pickup"
  );
  const [openCouponModal, setOpenCouponModal] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    values: {
      name: user?.first_name || guest_user?.name,
      phone: user?.phone || guest_user?.phone,
    },
    mode: "all",
    resolver: yupResolver(qrCartDetailScheme),
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/merchant-qr/${merchant_id}/${bmerchant_id}/cartorder`,
      title: "QrCartPage",
    });
  }, []);

  useEffect(() => {
    const orderDataString = localStorage.getItem(
      `order_merchant_${bmerchant_id}`
    );
    const orderData = JSON.parse(orderDataString);
    if (orderData) {
      setOrderdataResponse(orderData);
      setOrders(orderData.order_detail);
    } else {
      navigator("/food");
    }
  }, []);

  const handleGotoCheckoutPage = async (data) => {
    if (totals < 1) {
      setOpenPriceError(true);
      return;
    }
    const formattedOrders = orders.map((order) => ({
      menu_id: parseInt(order.menu.id, 10),
      menu_name: order.menu.name,
      quantity: parseInt(order.quantity, 10),
      note: order.note || "-",
      price: order.price,
      option: order.option,
      image: order.menu.image,
    }));
    const total_order = formattedOrders.reduce(
      (total, order) => total + parseInt(order.quantity, 10),
      0
    );

    const orderData = {
      customer_uuid: orderdataResponse.customer_uuid,
      customer_name: data.name,
      total_order: total_order,
      phone: data.phone, // Replace with actual customer UUID
      bmerchant_uuid: orderdataResponse.merchant_uuid, // Replace with actual merchant UUID
      time_to_get: "", // Use the selected time or null
      table_number: null, // This can be dynamic based on your application logic
      transaction_uuid: null,
      order_type: "PICKUP", // Order type in uppercase
      order_detail: formattedOrders, // The formatted order details
      deliver_fee: 0, // Delivery fee (can be dynamic)
      discount: 0, // Discount (can be dynamic)
      order_distance: 0, // Distance
      total_amount: totals, // Total amount calculated from orders
      payment_method: paymentType, // Payment method (can be dynamic)
      deliver_address: null, // Delivery address (can be dynamic)
      deliver_note: null,
    };

    localStorage.setItem(
      `checkout_data_${bmerchant_id}`,
      JSON.stringify(orderData)
    );
    navigator(`/merchant-qr/${merchant_id}/${bmerchant_id}/checkout`);
  };

  const totals = orders.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const subtotal = bmerchantService.vat ? totals / 1.07 : totals;
  const vat = bmerchantService.vat ? totals - subtotal : 0;

  const handleQuantityChange = (index, delta) => {
    setOrders((orders) => {
      const newOrders = [...orders];
      const currentQuantity = parseInt(newOrders[index].quantity, 10);
      const newItemQuantity = currentQuantity + delta;

      if (newItemQuantity > 0) {
        newOrders[index].quantity = newItemQuantity;
      } else {
        // Remove the item if the new quantity is 0 or less
        newOrders.splice(index, 1);
      }
      return newOrders;
    });
  };

  useEffect(() => {
    const cartDataString = localStorage.getItem(`merchant_${bmerchant_id}`);
    let cartData = cartDataString ? JSON.parse(cartDataString) : {};

    localStorage.setItem(
      `merchant_${bmerchant_id}`,
      JSON.stringify({
        ...cartData,
        totalPrice: subtotal,
        cartList: orders,
      })
    );
  }, [orders]); // Only re-run the effect if orders changes

  useEffect(() => {
    localStorage.setItem(
      `order_merchant_${bmerchant_id}`,
      JSON.stringify({
        ...orderdataResponse,
        order_detail: orders,
      })
    );
  }, [orders]); // Only re-run the effect if orders changes

  if (!isFetch) {
    return <LoadingScreen />;
  }
  if (orders.length === 0) {
    navigator(-1);
  }
  return (
    <>
      <Helmet>
        <title>{t("cart.order")}</title>
        <meta
          name="description"
          content="สั่งเดลิเวอรีผ่าน POMPKINS Food  x Order with Google ได้แล้ววันนี้"
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <meta property="og:url" content="https://pompkins.com/merchant-qr" />
      </Helmet>
      <Container maxWidth="lg" sx={{ p: { xs: 0, sm: 3, md: 4 } }}>
        {openPriceError && (
          <ModalPriceError
            open={openPriceError}
            handleClose={() => setOpenPriceError(false)}
          />
        )}
        <Card sx={{ borderRadius: { xs: "10px", sm: "15px" }, boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" fontWeight={500} mb={2}>
              รายการอาหารของคุณ
            </Typography>

            <Typography
              variant="body2"
              sx={{ fontSize: { xs: "16px", sm: "18px", md: "20px" }, mb: 2 }}
              color="#343434"
              mb={1}
            >
              ชื่อร้านค้า: {merchantData.name}
            </Typography>

            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "16px", sm: "18px" },
                    minWidth: "100px",
                  }}
                  color="#343434"
                >
                  ชื่อผู้รับ:
                </Typography>
                <TextInput
                  placeholder={"ชื่อผู้รับ"}
                  error={errors.name?.message}
                  {...register("name", { required: true })}
                  sx={{
                    flexGrow: 1,
                    "& .MuiOutlinedInput-root": {
                      height: { xs: "36px", sm: "40px" },
                      fontSize: { xs: "14px", sm: "16px" },
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "16px", sm: "18px" },
                    minWidth: "100px",
                  }}
                  color="#343434"
                >
                  เบอร์ติดต่อ:
                </Typography>
                <TextInput
                  placeholder={"เบอร์โทรศัพท์"}
                  error={errors.phone?.message}
                  {...register("phone", { required: true })}
                  sx={{
                    flexGrow: 1,
                    "& .MuiOutlinedInput-root": {
                      height: { xs: "36px", sm: "40px" },
                      fontSize: { xs: "14px", sm: "16px" },
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "18px", sm: "20px" } }}
                fontWeight={"medium"}
                color="#343434"
              >
                รายการอาหารที่สั่ง
              </Typography>
              <Button
                onClick={() =>
                  navigator(
                    `/merchant-qr/${merchant_id}/${bmerchant_id}/${merchantData.name}`
                  )
                }
                sx={{
                  color: "#58AC60",
                  ":hover": {
                    backgroundColor: "#FFF7EB",
                  },
                }}
              >
                {t("cartOrder.addMenu")}
              </Button>
            </Box>

            <Box sx={{ mb: 3 }}>
              {orders.map((item, index) => (
                <CartItem
                  key={`${item.menu.id}-${index}`}
                  item={item}
                  index={index}
                  handleQuantityChange={handleQuantityChange}
                  cartList={orders}
                  setCartList={setOrders}
                />
              ))}
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography sx={{ fontSize: { xs: "16px", sm: "18px" } }}>
                คูปอง
              </Typography>
              <Box>
                <Button
                  sx={{
                    color: "#797979",
                    p: "4px",
                    pl: "10px",
                    fontSize: { xs: "14px", sm: "16px" },
                    minWidth: 0,
                  }}
                  onClick={() => setOpenCouponModal(true)}
                >
                  {"ใช้้คูปอง >"}
                </Button>
              </Box>
            </Box>
            <Divider sx={{ mb: 2 }} />

            {/* ส่วนสรุป */}

            {/* {bmerchantService.vat && ( */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                color: "#343434",
              }}
            >
              {/* <Typography> {}ราคารวมก่อนภาษีมูลค่าเพิ่ม</Typography> */}
              <Typography sx={{ fontSize: { xs: "16px", sm: "18px" } }}>
                ค่าอาหาร
              </Typography>
              <Typography
                sx={{ fontSize: { xs: "16px", sm: "18px" } }}
              >{`฿ ${subtotal.toFixed(2)}`}</Typography>
            </Box>
            {/* )} */}

            {bmerchantService.vat && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                  VAT7%
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                >{`฿ ${vat
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                color: "#58AC60",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  fontWeight: "medium",
                }}
              >
                ทั้งหมด
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  fontWeight: "medium",
                }}
              >{`฿ ${totals
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</Typography>
            </Box>

            {paymentType !== "" && paymentType !== "CASH" && totals < 20 && (
              <Typography
                color={"#FF2C2C"}
                sx={{ mb: 2, fontSize: { xs: "14px", sm: "16px" } }}
              >
                *ขั้นต่ำ 20 บาท
              </Typography>
            )}

            <Divider sx={{ mb: 2 }} />

            <PaymentOptions
              availablePaymentList={availablePaymentList}
              paymentData={paymentData}
              bmerchantService={bmerchantService}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
            />

            <Button
              variant="contained"
              color="primary"
              disabled={
                paymentType === "" || (paymentType !== "CASH" && totals < 20)
              }
              fullWidth
              sx={{ mt: 2, p: "8px 22px", borderRadius: "2rem" }}
              onClick={handleSubmit(handleGotoCheckoutPage)}
            >
              เช็คเอาท์
            </Button>
          </CardContent>
        </Card>
      </Container>
      <CouponModal
        open={openCouponModal}
        onClose={() => setOpenCouponModal(false)}
      />
    </>
  );
};

export default QrCartPage;
